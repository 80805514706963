import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import AboutUsLayout from '../../components/AboutUsLayout';
import Faqs from '../../components/Faqs';
import SEO from '../../components/SEO/SEO';

import './about-page.scss';

const ConsumerAdvocacy = injectIntl(({ intl, location }) => {
  return (
    <AboutUsLayout intl={intl} location={location} pageName="about-us-page">
      <section className="consumer-advocacy-wrapper">
        <div>
          <section className="consumer-advocacy-content">
            <header>
              <h2 className="consumer-advocacy-title">
                <FormattedMessage id="pages.about.consumer-advocacy.title" />
              </h2>
            </header>
            <div>
              <p>
                <FormattedMessage id="pages.about.consumer-advocacy.text" />
              </p>
            </div>
          </section>
          <div>
            <Faqs pageName="about" brand="consumer-advocacy" listName="item" />
          </div>
        </div>
      </section>
    </AboutUsLayout>
  );
});

export default ConsumerAdvocacy;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO
    titleKey="pages.about.consumer-advocacy.page-title"
    descriptionKey="pages.about.consumer-advocacy.page-desc"
    intl={pageContext.intl}
  />
);
/* eslint-enable react/prop-types */
